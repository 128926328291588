
export const hours = [
    {
        time: '18',
        text: "18 horas"
    },
    {
        time: '18:30',
        text: "18:30"
    },
    {
        time: '19',
        text: "19 horas"
    },
    {
        time: '19:30',
        text: "19:30"
    },
    {
        time: '20',
        text: "20 horas"
    },
    {
        time: '20:30',
        text: "20:30"
    },
    {
        time: '21',
        text: "21 horas"
    },
    {
        time: '21:30',
        text: "21:30"
    },
    {
        time: '22',
        text: "22 horas"
    },
]