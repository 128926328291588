export const hours = [
    {
        "day": "Domingo",
        "hours": "17:30 as 23:00"
    },
    {
        "day": "Segunda",
        "hours": "fechado"
    },
    {
        "day": "Terça",
        "hours": "17:30 as 23:00"
    },
    {
        "day": "Quarta",
        "hours": "17:30 as 23:00"
    },
    {
        "day": "Quinta",
        "hours": "17:30 as 23:00"
    },
    {
        "day": "Sexta",
        "hours": "17:30 as 23:00"
    },
    {
        "day": "Sábado",
        "hours": "17:30 as 23:00"
    },
]