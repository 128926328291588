import {React, useState, useEffect} from 'react'
import axios from 'axios'

export default function Banner(props) {

    const handleShowRoutes = () =>{
        props.schedule ? props.setSchedule(false) : props.setSchedule(true)
    }

    return (

        <div className="flex-row" style={{justifyContent: 'space-evenly', color: '#404355', width: '98vw', lineHeight: '0.8em', marginTop: '0px'}}>
            <div onClick={handleShowRoutes} >
                <p style={{marginBottom: '-15px'}}>Ver Taxas</p>
                <p style={{fontSize: '0.8em'}}>de entrega</p>
            </div>
            <div >
                <p style={{marginBottom: '-15px'}}>{props.shop[0] ? props.shop[0].waitingTime : ""}</p>
                <p style={{fontSize: '0.8em'}}>minutos</p>
            </div>
            <div>
                <p style={{marginBottom: '-15px'}}>R$ 10,00</p>
                <p style={{fontSize: '0.8em'}}>mínimo</p>
            </div>

            
        </div>
    )
}
