import {React, useRef} from 'react'
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { BiBeer, BiDrink } from "react-icons/bi";
import {GiCigar, GiWaterBottle} from "react-icons/gi"
import {ReactComponent as Vodka} from "../assets/vodka.svg"
import {ReactComponent as Cola} from "../assets/cola.svg"
import {ReactComponent as Coco} from "../assets/coco.svg"
import {ReactComponent as Combo} from "../assets/combo.svg"
import {ReactComponent as Porcao} from "../assets/portion.svg"
import {ReactComponent as Corote} from "../assets/corote.svg"
import {ReactComponent as Sobremesa} from "../assets/sobremesa.svg"
import {ReactComponent as Leaves} from "../assets/leaves.svg"
import {ReactComponent as Burger} from "../assets/burger.svg"
import { ReactComponent as Energetico } from '../assets/energetico.svg';
import {ReactComponent as Soda} from '../assets/soda.svg'
import {ReactComponent as Pizza} from '../assets/Pizza.svg'
import {ReactComponent as Esfiha} from '../assets/esfiha.svg'
import {ReactComponent as Pastel} from '../assets/pastel.svg'
import {ReactComponent as HotDog} from '../assets/hotdog.svg'
import {ReactComponent as Panqueca} from '../assets/panqueca.svg'
import { hours } from './JSON/hours';
import combo1 from '../assets/combo1.jpeg'


import raango from '../assets/logo2.png'
import './css/nav-mobile.css'

import {scroller, animateScroll} from 'react-scroll'

import {
    BrowserRouter as Router,
    Link


  } from "react-router-dom";
import Banner from './components.utils/Banner';


export default function NavMobile(props) {

    const myRef = useRef(null)

    
    
    const sort = require('../utils/sort').sort

    const products = props.productsDB.filter(prod=> {
  
            return prod;
        

    })

    const subcategories = sort(products)

    const Tower =()=> {
        return (
            <svg viewBox="0 0 24 24" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier" fill="#fff"><defs></defs><polygon class="cls-1" points="19.42 22.5 4.58 22.5 2.46 18.68 21.55 18.68 19.42 22.5"></polygon><line class="cls-1" x1="0.55" y1="18.68" x2="23.45" y2="18.68"></line><rect class="cls-1" x="2.45" y="14.86" width="19.09" height="3.82" rx="1.91"></rect><line class="cls-1" x1="14.17" y1="11.05" x2="10.09" y2="11.05"></line><path class="cls-1" d="M17.41,11.05h2.23A1.91,1.91,0,0,1,21.55,13,1.84,1.84,0,0,1,21,14.3a1.87,1.87,0,0,1-1.34.56H4.36A1.92,1.92,0,0,1,2.45,13,1.83,1.83,0,0,1,3,11.61a1.87,1.87,0,0,1,1.34-.56H6.27"></path><path class="cls-1" d="M6.27,7.23H4.36A1.87,1.87,0,0,0,3,7.79a1.84,1.84,0,0,0-.57,1.35,1.92,1.92,0,0,0,1.91,1.91H6.27"></path><path class="cls-1" d="M17.73,7.23h1.91a1.92,1.92,0,0,1,1.91,1.91,1.95,1.95,0,0,1-1.91,1.91H17.41"></path><line class="cls-1" x1="14.17" y1="11.05" x2="10.09" y2="11.05"></line><path class="cls-1" d="M17.73,7.23V10a2.1,2.1,0,0,1-1.43,1.93,1.91,1.91,0,0,1-2.39-1.82,2,2,0,0,0-1.6-1.91,1.91,1.91,0,0,0-2.22,1.88v2.75a2,2,0,0,1-1.58,2A1.92,1.92,0,0,1,6.27,13V7.23A1.91,1.91,0,0,1,8.18,5.32h7.64A1.91,1.91,0,0,1,17.73,7.23Z"></path><circle class="cls-1" cx="12" cy="3.41" r="1.91"></circle></g></svg>
        )
    }





    const iconMenuClick = (menu) =>{
        myRef?.current?.scrollIntoView()
        props.setConvProps(menu)
        props.setShowPromo(false)
        props.handleTabClick(menu)
        props.handleScroll()
    }

    const addPromo = () =>{
        var borbaBurger = {

            "_id": "63d9197d21bb339c2e679603",
            "code": 21,
            "name": "Combo Reggaton",
            "short": "HAMBURGER 200gr + Nuggets + COCA 220ML ACOMPANHA MOLHO GOURMET A SUA ESCOLHA",
            "long": "Pão gergelin, hambuguer carne, mussarela, picles, bacon em tiras, molho da Casa",
            "photo": "https://firebasestorage.googleapis.com/v0/b/athenabackend-1b88b.appspot.com/o/melten%2FPost%20Instagram%20Promo%C3%A7%C3%A3o%20Hamb%C3%BArguer%20Amarelo%20e%20Vermelho%20.png?alt=media&token=8ca6417f-58e9-48ca-9659-718042b29fe9",
            "price": 29.99,
            "available": true,
            "variations": [],
            "flavours": [
                ""
            ],
            "group": "Combos",
            "subGroup": "Combos",
            "__v": 0
            
        }

        // props.setShowPromo(false)

        props.addProduct(borbaBurger)
        
    }



    const HandleHours = () =>{
        var date = new Date()

        // console.log(date.getDay())

        if(props.shop[0]){
            if(props.shop[0].isOpen){
                switch (date.getDay()) {
                    case 1:
                        return <p style={{color: 'red'}}>Fechado Agora <br></br> <br></br> </p>                       
    

                
                    default:
                        if(date.getHours() < 17 && date.getHours() >= 0 ){
                            return <p style={{color: 'red'}}>Fechado Agora<br></br> <br></br> </p>
                        }            
                        else {
                            
                            return <p></p>
                        }
                }


            }    
            else{
                return <p style={{color: 'red'}}>Fechado Agora <br></br></p>
            }           
        }

        else return null;



    }

    const NavBar = () =>{

        const NavIcon = ({sub}) =>{

            switch (sub){
                case 'RapiEsfihas':
                    return <Esfiha className="nav-icon" />
                    break;
                
                case 'Pastel':
                case "Calzone":
                    return <Pastel className='nav-icon' />
                    break;

                case 'Bebidas ':
                    return <Soda className='nav-icon' />
                    break
                
                case 'Sobremesas':
                    return <Sobremesa className='nav-icon' />

                case 'Hot Dog':
                    return <HotDog className='nav-icon' />
                
                case 'Ice':
                    return <Cola className='nav-icon' />

                case 'Corote':
                    return <Corote className='nav-icon' />

                case 'Combos':
                    return <Combo className='nav-icon' />

                case 'Porções  ':
                    return <Porcao className='nav-icon' />

                case 'Rapiburguer':
                case "Kids ":
                case "Xis Tradicional ":
                    return <Burger className='nav-icon' />

                case 'Panquecas':
                    return <Panqueca className='nav-icon' />

                case 'Torres Salgadas ':
                    return <Tower className='nav-icon' />


                

                default: return <Pizza className='nav-icon' />;
            }
        }

            return <div  className='nav-on-cart'>  
                {subcategories.map(sub=>(
                                        <Link 
                                            onClick={()=>iconMenuClick(sub)} 
                                            className={props.convProps === sub ? "link-nav-on-cart link-nav-cart-active" : "link-nav-on-cart"} 
                                            to="/conveniencia"
                                            >
                                            <NavIcon sub={sub} />
                                            {sub}</Link>
                                    
                ))}
      
            </div>

      
    }

    // console.log(props.shop[0].schedule)




    return (
        <div>
            {/* <Nav /> */}

            <NavBar  />
           
            <div ref={myRef}/>
            <HandleHours />

            {/* <div onClick={addPromo} style={{display: props.showPromo ? 'block' : 'none', width: window.innerWidth > 600 ? '35vw' : 'auto'}}>
                            <h3>Combos do dia</h3>
                            <img src={combo1} style={{width: window.innerWidth > 600 ? '30vw' : '80vw'}} alt="" />
            </div>  */}

            {/* <img src={"./banner.jpg"} style={{width: window.innerWidth}} /> */}

            <div className='flex-column' style={{display: props.showPromo ? 'flex' : 'none', color: '#404355', justifyContent: 'space-between', alignItems: 'center', width: '90vw'}}>
                <Banner apiUrl={props.apiUrl} shop={props.shop} schedule={props.schedule} setSchedule={props.setSchedule}/>
            {/* <img src={freeShip} alt="promo frete grátis" style={{marginLeft: '25px', marginTop: '30px',width: '90vw', alignSelf: 'center', justifySelf: 'center'}} /> */}
                <h3 style={{marginTop: '40px'}}>Horário de Atendimento</h3>
                {/* <p style={{fontSize: '0.8em', marginTop: 0}}>{props.shop[0] ? "Tempo de espera " + props.shop[0].waitingTime : ""}</p> */}

                    {hours?.map(item =>(
                            <div className="flex-row" style={{marginLeft: '20px',justifyContent: 'space-between', width: '90vw', height: '25px'}}>
                                <p>{item.day}</p>
                                <p>{item.hours}</p>
                            </div>
                    ))}
                

                

               



                
                                {/* <img src={"./banner.jpg"} style={{width: window.innerWidth, marginTop: '50px', marginLeft: '10px', marginBottom: '0px'}} /> */}
            </div>
            
            <div className='flex-column' style={{display: props.showPromo ? 'flex' : 'none',marginTop: '50px' ,justifyContent: 'space-between', alignItems: 'center', width: '90vw', color: '#404355'}}>
                <h4>{props.shop[0]?.name}</h4>
                <p>visite</p>
                <div className="flex-row" style={{width: '80vw', justifyContent: 'space-evenly'}}>
                    <a href={props.shop[0]?.facebook} target="_blank" rel="noreferrer" style={{fontSize: '2em', color: "#404355"}}><FaFacebookF /></a>
                    <a href={props.shop[0]?.instagram}  target="_blank" rel="noreferrer" style={{fontSize: '2em', color: "#404355"}}><FaInstagram /></a>
                </div>
                <a className="ext-links" href={"https://api.whatsapp.com/send?l=pt_BR&phone=55"+ props.shop[0]?.whatsapp} target="_blank" rel="noreferrer">whatsapp</a>
                <a className="ext-links" href={"mailto:" + props.shop[0]?.email}>{props.shop[0]?.email}</a>
                <a className="ref-rango" target="_blank" rel="noreferrer" href="https://www.letecacom.com.br">
                    <img width="50px" height="50px" src={raango} ></img>
                    <p>Desenvolvido por Lt Soluções web</p>
                </a>

            </div>

            
    </div>
    )
}

const colorStyle = {
    color: '#575151',
    fill: '#575151',
    stroke: '#575151',
}
