import React, {useEffect, useState, useRef} from 'react';
import './components.css';

import BasicFood from './controllers/BasicFood';
import MilkDerivates from './controllers/MilkDerivates';
import Bomboniere from './controllers/Bomboniere';
import MorningFood from './controllers/MorningFood';
import IceCream from './controllers/IceCream';
import Routes from '../components/components.utils/Routes'

import { animateScroll, scroller } from 'react-scroll';

import Checkout from './Checkout'
import Product from './Product'
import Productmobile from './Productmobile'
import Payment from './Payment';

import UserOrders from './UserOrders'

import NavMobile from './NavMobile'

import axios from 'axios';


import rango from '../assets/kantinho.jpeg'





import clock from '../assets/Foto-Loading-PNG.png'
import cluck from '../assets/sound/487452__ranner__click.wav'
import useSound from 'use-sound';


import { FiShoppingBag } from "react-icons/fi";
import {FaArrowLeft} from 'react-icons/fa'
import {RiUser3Line} from 'react-icons/ri'

import checked from '../assets/checked.png'

import {
    Route,
    HashRouter,  
    Link,
    Redirect,
    useLocation, 
    Switch,
    useHistory

  } from "react-router-dom";
import Frozen from './controllers/Frozen';
import Drinks from './controllers/Drinks';
import Cleaning from './controllers/Cleaning';
import Barbecue from './controllers/Barbecue';
import PersonalCleaning from './controllers/PersonalCleaning';
import DailyPromo from './controllers/DailyPromo';
import { IoIceCream } from 'react-icons/io5';
import Hortifruit from './controllers/Hortifruit';
import Pet from './controllers/Pet';
import Conveniencia from './controllers/Conveniencia';
import { Element } from 'react-scroll';



export default function Menu(props) {

    const[productsDB, setProductsDB] =  useState([])
    const myRef = useRef(null)
   
    
    
    const [x, setX] = useState({});
    const [product, setProduct] = useState({name: '', short: '', price: '', img:'', qty: 0});
    // const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')) ? JSON.parse(localStorage.getItem('cart')) : [] );
    // const [total, setTotal] = useState(JSON.parse(localStorage.getItem('total')) ? JSON.parse(localStorage.getItem('total')) : 0);
    const [cart, setCart] = useState([]);
    const [total, setTotal] = useState(0);
    const [show, setShow] = useState(false);
    const [subtotal, setSubtotal] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [q, setQ] = useState(1);
    const [pMobile, setpMobile] = useState(false);
    const [tabName, setTabName] = useState("");
    const [p, setP] = useState("");
    const[varProduct, setVarProduct] = useState(false);
    const[showAlert, setShowAlert] = useState(false);
    const[plusBurger, setPlusBurger] = useState(false);
    const[sushiExtra, setSushiExtra] = useState(false);

    
    const[limit, setLimit] = useState(1)
    const [multiplier, setMultiplier] = useState(1)
   
    



    const[click, setClick] = useState(false);

    const[mobileNav, setMobileNav] = useState(false);
    const[closeOrder, setCloseOrder] = useState(false);

    const[loading, setLoading] = useState(false)
    const[arrival, setArrival] = useState(false)

    const[pizza, setPizza] = useState({small: false, medium: false, big:false})
    const[firstFlavour, setFirstFlavour] = useState('')
    const[secondFlavour, setSecondFlavour] = useState('')
    const[thirdFlavour, setThirdFlavour] = useState('')
    const[hereVariations, setHereVariations] = useState([])

    

    const[observation, setObservation] = useState('')

    const [success, setSuccess] = useState(false)

    const [showPromo, setShowPromo] = useState(true)

    const [schedule, setSchedule] = useState(false)

    const [convProps, setConvProps] = useState('')

    const[extrasCart, setExtrasCart] = useState([])
    const[extraPrice, setExtraPrice] = useState(0);

    const[addOnCart, setAddOnCart] = useState([])
    const[addOnPrice, setAddOnPrice] = useState(0)
    const[bordaCart, setBordaCart] = useState([])

    

    useEffect(()=>{
        displayProduct();
        setLocalStorage('cart', cart)
        setLocalStorage('total', total)
        
    })

    useEffect(()=>{
        
        if(window.innerWidth < 600){
            handleArrival()
        }

      

    }, [])

    useEffect(() => {
        axios.get(props.apiUrl + 'products')
            .then(res=> setProductsDB(res.data))
            .catch(err=> console.log(err))

    }, [])

    const[shop, setShop] = useState([])



    

    useEffect(() => {

    }, [])

   const displayProduct = (props) =>{
        setProduct(x);
        setQuantity(q);
        setSubtotal(product.price? product.promo? product.promoPrice * quantity : product.price * quantity : 0)
        setCart(cart)
        
   }

    const addProduct = (props) =>{

        console.log(props)
        
        setExtrasCart([])
        // console.log(extrasCart.adicionais)
        setAddOnCart([])
        // setBordaCart([])
        setObservation('')
        setP('')
        setFirstFlavour('')
        setSecondFlavour('')
        setThirdFlavour('')
        setHereVariations([])
        setBordaCart([])
        setX(props);
        setQ(1)
        

        if(window.innerWidth < 600){
            setpMobile(true)
        }

        displayExtras(props)


    }



    const displayExtras = (props) =>{
        if(props.group === 'Steaks'){
            setVarProduct(true)
            setPlusBurger(false)
            setSushiExtra(false)  
            setPizza({small: false, medium: false, big: false})
        }

        else if(props.group === 'Burguers'){
            setPlusBurger(true)
            setVarProduct(false)
            setSushiExtra(false)
            setPizza({small: false, medium: false, big: false})
            
        }

        else if(props.group === 'Assados'){
            setPlusBurger(false)
            setVarProduct(true)
            setSushiExtra(false)
            setPizza({small: false, medium: false, big: false})
            
        }

        else if(props.group === 'Combinados Sushi' || props.group === "Especiais Sushi" ){
            setPlusBurger(false)
            setVarProduct(false)
            setSushiExtra(true)
            setPizza({small: false, medium: false, big: false})
        }

        else if(props.group === 'PizzaP'){
            setPlusBurger(false)
            setVarProduct(false)
            setSushiExtra(false)
            setPizza({small: true, medium: false, big:false})
            
        }

        else if(props.group === 'PizzaM'){
            setPlusBurger(false)
            setVarProduct(false)
            setSushiExtra(false)
            setPizza({small: false, medium: true, big:false})
            
        }

        else if(props.group === 'PizzaG'){
            setPlusBurger(false)
            setVarProduct(false)
            setSushiExtra(false)
            setPizza({small: false, medium: false, big:true})
            
        }

        else { 
            setVarProduct(false)
            setPlusBurger(false)
            setSushiExtra(false)
            setPizza({small: false, medium: false, big: false})
        }


        
    }

    const handleMeatPoint = (props) =>{
        setP(props)
        console.log(p)
    }

    const handlePlusBurger = (props) =>{
        setP(props);
        setExtraPrice(5)

        if(props === ""){
            setExtraPrice(0)
        }

    }

    const handleTabClick = (props) =>{

        setMobileNav(false)
        setTabName(props);
        animateScroll.scrollMore(300, {
            duration: 500
        })

        handleLoading()


        let fakeProduct = {
            name: "",
            price: 0,
            photo: "",
            short: "",
            long: "",
            group:""
        }

        if(window.innerWidth > 600){
            addProduct(fakeProduct)
        }

        
        
        if(props === 'Burger'){
            setPlusBurger(true)
            setVarProduct(false)
            setSushiExtra(false)
            
        }
        
        else { 
            setVarProduct(false)
            setPlusBurger(false)
            setSushiExtra(false)
        }
    }

 
    const setLocalStorage = (key, value) =>{
        
        localStorage.setItem(key, JSON.stringify(value));

        localStorage.setItem(key, JSON.stringify(value));
        
    }


    const addToCart = (props) =>{
        

        let ads = []
        let divider = bordaCart.length

        addOnCart.forEach(add=>{
            ads.push(add)
        })

        bordaCart.forEach(add=>{

            if(divider !== 0){
                add.price = add.price / divider
                ads.push(add)
            }
            else add.push(add)

        })


        let cartProduct = {
            name: props.name,
            code: props.code,
            subTotal: props.subTotal,
            quantity: props.quantity,
            table: props.table,
            mode: props.mode,
            group: props.group,
            extrasCart: extrasCart,
            addOnCart: ads,
            point: hereVariations.length > 0 && p.length > 0 ? 
            p + " e " + hereVariations : hereVariations.length > 0 ? 
                    hereVariations.join(', ') : p.length > 0 ? 
                    p  : "" ,
            observation: observation,
            firstFlavour: firstFlavour,
            secondFlavour: secondFlavour,
            thirdFlavour: thirdFlavour
        };

        let extrasPrice = 0;
        let addOnsPrice = 0;
        let bordasPrice = 0;
        var hereQty = 0;

        cartProduct.quantity += hereQty;
  
        cartProduct.extrasCart = extrasCart;
        cartProduct.multiplier = multiplier
        
        for(let i=0; i < cartProduct.extrasCart.length; i++){
            if(cartProduct.group === "RapiEsfihas"){
                cartProduct.extrasCart[i].quantity = 1;
            }
            else {
                cartProduct.extrasCart[i].quantity = cartProduct.quantity;
            }
            
        }

        for(let i=0; i < cartProduct.extrasCart.length; i++){
            extrasPrice += cartProduct.extrasCart[i].price * cartProduct.extrasCart[i].quantity;
            
        }

        for(let i=0; i < cartProduct.addOnCart.length; i++){
            cartProduct.addOnCart[i].quantity = cartProduct.quantity;
        }

        for(let i=0; i < cartProduct.addOnCart.length; i++){
            addOnsPrice += cartProduct.addOnCart[i].price * cartProduct.addOnCart[i].quantity;
            
        }

        // for(let i=0; i < cartProduct.bordasCart.length; i++){
        //     bordasPrice += cartProduct.bordasCart[i].price;
            
        // }


        cartProduct.extrasPrice = extrasPrice;
        cartProduct.addOnPrice = addOnsPrice;
        // cartProduct.bordasPrice = bordasPrice;
       
        if(varProduct){
            cartProduct.point = p;
            
        }
        if(plusBurger){
            cartProduct.observation = observation;
            
        }  

        setCart(current => [...current, cartProduct])
        setTotal(current=> current + cartProduct.subTotal + cartProduct.extrasPrice + cartProduct.addOnPrice )
        
        if(window.innerWidth < 600 && !props.random){
            setpMobile(false)
        }

        else if(props.random){
            alert('adicionado ao carrinho')
        }
        
        handleButtonClicked()

        handleAlert()
  

    }

    const removeFromCart = (props) =>{
        cart.splice(cart.indexOf(props), 1)


        setTotal(current => current-props.subTotal - props.extrasPrice - props.addOnPrice)

        if(total < 0){
            setTotal(0)
        }

    }

    const removeExtra = (cart, checkCart, extra) =>{
    
        cart.subTotal -= checkCart[checkCart.indexOf(extra)].price * checkCart[checkCart.indexOf(extra)].quantity;
        
        setTotal(current => current - (checkCart[checkCart.indexOf(extra)].price * checkCart[checkCart.indexOf(extra)].quantity))

        checkCart.splice(checkCart.indexOf(extra), 1)

        play()

        
    }

    const removeAddOn = (cart, checkCart, extra) =>{
        
        cart.subTotal -= checkCart[checkCart.indexOf(extra)].price * checkCart[checkCart.indexOf(extra)].quantity;
        
        setTotal(current => current - (checkCart[checkCart.indexOf(extra)].price * checkCart[checkCart.indexOf(extra)].quantity))

        checkCart.splice(checkCart.indexOf(extra), 1)

        play()
    

        
    }

    const addOne = ()=>{
        setQ(current=> current + 1)
    }

    const decreaseOne = ()=>{
        if(q > 1){
        setQ(current=> current - 1)
        }
        else setQ(1)
    }

    const showModal = () =>{
        show ? setShow(false) : setShow(true)
        setCloseOrder(false)
    }

    const showMobileNav = () =>{
        mobileNav ? setMobileNav(false) : setMobileNav(true);
        
    }

    const showPmobile = () =>{
        pMobile ? setpMobile(false) : setpMobile(true)
    }

    const handleAlert = () =>{
        setShowAlert(true)

        // setTimeout(()=>{
        //     setShowAlert(false)
        // }, 3000)
    }

    const handleButtonClicked = () =>{

        setClick(true)
        

        setTimeout(()=>{
            setClick(false)
        }, 3000)

    }

    const handleCloseOrder = () =>{
        setCloseOrder(true)
        
    }

    const handleSuccess = ()=>{
        setSuccess(true)

        setTimeout(() => {
            
            setSuccess(false)
        }, 3000);
    }

    const CheckoutOrClose = () =>{
        return closeOrder ?     
        <Payment 
        user={props.user}
        total={total}
        cart={cart}
        foo={showModal}
        setCart={setCart}
        setExtrasCart={setExtrasCart}
        setTotal={setTotal}
        handleSuccess={handleSuccess}
        apiUrl={props.apiUrl}
        shop={props.shop}
        /> 
        :
        <Checkout 
        total={total}
        cart={cart}
        foo={showModal}
        remove={removeFromCart}
        removeExtra={removeExtra}
        handleCloseOrder={handleCloseOrder}
        shop={props.shop}
        apiUrl={props.apiUrl}
        removeAddOn={removeAddOn}
        multiplier={multiplier}
        />   

    }



    const handleLoading = () =>{
        setLoading(true)

        setTimeout(()=>{
            setLoading(false)
        }, 1000)


    }

    const handleArrival = () =>
    {
        setArrival(true)
        setTimeout(()=>{
            setArrival(false)
        }, 3000)
    }

    //todo default route with promo

    const [play] = useSound(cluck);

    const handleShowPromo = () =>{
        play()
        setShowPromo(false)
    }

    const handleScroll =()=>{
        // myRef.current?.scrollIntoView({behavior: 'smooth'})
        // console.log(myRef.current)
        // scroller.scrollTo('products', {
        //     duration: '500',
        //     smooth: true
        // })
    }



    
    
    
    return (
        <div className="box">
            <HashRouter history={props.history}>
                
                <div className="container" >

                    <div className="top-mobile">
                        <div>  
                           <Link to="/menu" onClick={()=>setShowPromo(true)} ><FaArrowLeft className="mobile-icon" style={{color: 'white'}} /></Link>
                        </div>

                        <Link to ='/userorders'className="link-nav-on-cart" style={{color: 'white', fontSize: '0.9em',marginTop: '10px', marginRight: '20px', marginBottom: '10px'}}>
                            <RiUser3Line onClick={handleShowPromo}style={{color: 'white', fontSize: '2em', textAlign: 'center', alignSelf: 'center', marginBottom: '0px'}} />
                            seus pedidos                                
                        </Link>

                    </div>


                    <div className="nav">
                        <img alt="logo de Borba" src={rango} />

                            <div className="entries-button">
                                <h3><Link onClick={()=>handleTabClick("Entradas")} className="link" to="/entradas">Pizzas</Link></h3>
                            </div>

                            <div className="burger-button">
                                <h3><Link onClick={()=>handleTabClick("Burger")} className="link" to="/burger">Burger</Link></h3>
                            </div>

                            {/* <div className="grilled-button">
                                <h3><Link onClick={()=>handleTabClick("Steaks")} className="link" to="/grelhados">Steaks</Link></h3>
                            </div> */}

                            <div className="sushi-button">
                                <h3><Link onClick={()=>handleTabClick("Sushi")} className="link" to="/sushi">Sushi</Link></h3>
                            </div>

                            <div className="drinks-button">
                                <h3><Link onClick={()=>handleTabClick("Bebidas")} className="link" to="/drinks">Bebidas</Link></h3>
                            </div>
{/* 
                            <div className="drinks-button">
                                <h3><Link onClick={()=>handleTabClick("Sobremesas")} className="link" to="/sobremesas">Sobremesas</Link></h3>
                            </div> */}

                            
                        <div>
                            <p className="basket-counter">{cart.length}</p>
                            <FiShoppingBag className="basket-icon" onClick={showModal}/>
                        </div>

                    </div>
                    <div className="card">
                             

                        <NavMobile
                            style={{display: window.innerWidth < 600 ? 'flex' : 'none'}}
                            handleTabClick={handleTabClick}
                            addProduct={addProduct}
                            showPromo={showPromo}
                            setShowPromo={setShowPromo}
                            shop={props.shop}
                            schedule={schedule}
                            setSchedule={setSchedule}
                            productsDB={productsDB}
                            setConvProps={setConvProps}
                            convProps={convProps}
                            handleScroll={handleScroll}
                            />                     
                      
                        <Switch  >
                           
                            <Element name='products' ref={myRef}>
                                <Route  path="/conveniencia">
                                    
                                    <Conveniencia
                                        
                                        apiUrl={props.apiUrl}
                                        foo={addProduct}
                                        convProps={convProps}
                                        />
                                </Route>
                            </Element>

                            <Route path="/userorders">
                                <UserOrders />
                            </Route>


                        <Redirect from="*" to="/" />

                        
                        </Switch>
                        <div ref={myRef} />
              
                        <div className="alert" style={{display: cart.length > 0 ? 'flex' : 'none'}} onClick={()=>{setShow(true)}}>
                            <FiShoppingBag className="mobile-icon" />
                            <p className="basket-counter-mobile">{cart.length}</p>
                            <p>VER CARRINHO</p>
                            <p>{'R$ ' + total.toFixed(2)}</p>
                        </div>                        
      
                    </div>

                    <Productmobile
                product={product}
                quantity={quantity}
                subtotal={subtotal}
                addOne={addOne}
                decreaseOne={decreaseOne}
                addToCart={addToCart}
                showPmobile ={showPmobile}
                pMobile={pMobile}
                handleMeatPoint={handleMeatPoint}
                varProduct={varProduct}
                handlePlusBurger={handlePlusBurger}
                extraPrice={extraPrice}
                plusBurger={plusBurger}
                sushiExtra={sushiExtra}
                click={click}
                setExtrasCart={setExtrasCart}
                extrasCart={extrasCart}
                setObservation={setObservation}
                observation={observation}
                cart={cart}
                setCart={setCart}
                p={p}
                pizza={pizza}
                hereVariations={hereVariations}
                setHereVariations={setHereVariations}
                productsDB={productsDB}
                apiUrl={props.apiUrl}
                setAddOnCart={setAddOnCart}
                addOnCart={addOnCart}
                setBordaCart={setBordaCart}
                bordaCart={bordaCart}
                limit={limit}
                setLimit={setLimit}
                multiplier={multiplier}
                setMultiplier={setMultiplier}

                setP={setP}

            />

            <Product
                product={product}
                quantity={quantity}
                subtotal={subtotal}
                addOne={addOne}
                decreaseOne={decreaseOne}
                addToCart={addToCart}
                showPmobile ={showPmobile}
                pMobile={pMobile}
                handleMeatPoint={handleMeatPoint}
                varProduct={varProduct}
                handlePlusBurger={handlePlusBurger}
                extraPrice={extraPrice}
                plusBurger={plusBurger}
                sushiExtra={sushiExtra}
                click={click}
                setExtrasCart={setExtrasCart}
                extrasCart={extrasCart}
                setObservation={setObservation}
                observation={observation}
                cart={cart}
                setCart={setCart}
                p={p}
                pizza={pizza}
                hereVariations={hereVariations}
                setHereVariations={setHereVariations}
                productsDB={productsDB}
                apiUrl={props.apiUrl}
                setAddOnCart={setAddOnCart}
                addOnCart={addOnCart}

                setP={setP}
                />

            </div>
                 
            <div style={{display:  show ? 'flex' : 'none'}} className="modal">
                <CheckoutOrClose />
            </div>

            <div style={{display:  schedule ? 'flex' : 'none'}} className="modal">
                <Routes apiUrl={props.apiUrl} setSchedule={setSchedule} />
            </div>

            <div className="loading-modal" style={{display: loading ? 'flex' : 'none'}} >
                <img src={clock} alt="loading" className="clock-loading" />
                <h4>Só um Segundo...</h4>
                {/* <p>Estamos buscando o cardápio para você!</p> */}
            </div>

            {handleArrival}

            </HashRouter>

            <div className="confirmation-modal" style={{display: success ? 'flex' : 'none'}} >
                <img src={checked} alt="" style={{width: '100px'}} />
                <p>seu pedido foi enviado com sucesso</p>
                <p>logo enviaremos a confirmação quando ele estiver pronto</p>

            </div>

            <div className="arrival-modal" style={{display: arrival ? 'flex' : 'none'}}>
                <img src={rango} alt="logo" />
            </div>



        </div>

    )
}
