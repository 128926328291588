import {React, useState} from 'react'
import frente from '../../assets/interior.jpg'
import logo from '../../assets/zedabrahma.jpeg'

export default function Site() {
    const [modal, setModal] = useState(false)

    const styles ={
        header: {
            width: '100vw',
            height: '15vh',
            backgroundColor: '#202020',
            
    
        },
        body: {
            width: '100vw',
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: "center",
            flexDirection: 'column'
        },
        imgFrame: {
            width: '100vw',
            height: '85vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center ',
            overflow: 'hidden',
            marginTop: '-5px',
            backgroundImage: `url(${frente})`,
            backgroundRepeat: 'no-reapeat',
            bakgroundSize: 'cover',
            
        },
        banner:{
            objectFit: 'cover',
            width: '100%'
        },
        about: {
            width: '50%',
            backgroundColor: 'rgba(255,255,255, 0.7)',
            height: '70%',
            backdropFilter: 'blur(4px)',
            borderRadius: '15px',
            color: '#020'
        },
        logo: {
            width: '80px',
            marginTop: '10px'
        },
        aboutText: {
            marginLeft: '40px',
            marginRight: '40px'
        },
        btn: {
            width: '80px',
            backgroundColor: '#020202',
            padding: '10px',
            color: 'white',
            borderRadius: '10px',
            cursor: 'pointer'
        },
        modal: {
            width: '100vw',
            height: '100vh',
            display: modal ? 'flex' : 'none',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'fixed',
            top: '0',
            left: '0',
            zIndex: '666',
            backgroundColor: 'rgba(255,255,255, 0.8)',
            backdropFilter: 'blur(4px)',
            flexDirection: 'column'
        },
        footer: {
            width: '99.5vw',
            height: '30vh',
            backgroundColor: '#020202',
            display: 'flex',
            color: 'white',
            justifyContent: 'space-evenly',
            alignItems: 'center'
            
        }
    }
    

    return (
        <div style={styles.body}>
            <iframe style={styles.iframeBox} width="580px" height="580px" src="https://rapidaofraiburgo.com.br" />

        </div>
    )

}


