import  {React,useEffect, useState} from 'react';
import './components.css';
import './css/checkout.css';
import InputMask from 'react-input-mask';
import axios from 'axios';

import clock from '../assets/Foto-Loading-PNG.png'
import denied from '../assets/denied.png'

// import bairros from './JSON/bairros.json'




import {BiChevronLeftCircle} from 'react-icons/bi'
import {RiMastercardLine} from 'react-icons/ri'
import {FaRegCopy} from 'react-icons/fa'
import { hours } from './JSON/schedule';

export default function Payment(props) {

    const[frete, setFrete] = useState(0);
    const[final, setFinal] = useState(0);
    const[savedOrder, setSavedOrder] = useState(JSON.parse(localStorage.getItem('order')) ? JSON.parse(localStorage.getItem('order')) : {})
    
    // const[cardFinal, setCardFinal] = useState(0)

    const[motoboy, setMotoboy] = useState(false)

    const[phone, setPhone] = useState('')
    const[adress, setAdress] = useState('')

    const[deliver, setDeliver] = useState(false)

    const[name, setName]= useState('');

    const[newName, setNewName]= useState('')
    const[cardNumber, setCardNumber]= useState("");
    const[secureCode, setSecureCode]= useState('')
    const[expMonth, setExpMonth]= useState('')
    const[expYear, setExpYear]= useState('')
    const[method, setMethod] = useState('')
    const[option, setOption] = useState('')
    const[nb, setNb] = useState('')
    const[city, setCity] = useState('')
    const[houseNumber, setHouseNumber] = useState('')
    const[reference, setReference] = useState('')

    const[loading, setLoading] = useState(false)
    const[paymentProcess, setPaymentProcess] = useState(false)

    
    const[doDeliver, setDoDeliver] = useState(false)
    const[dontDeliver, setDontDeliver] = useState(false)


    const[cep, setCep] = useState(0)
    const[cepRes, setCepRes] = useState({})

    const[payByApp, setPayByApp] = useState(false)

    const[takeAway, setTakeAway] = useState(false)

    const [input, setInput] = useState({adress: '', name: '', phone: ''})

    const[motoboyCard, setMotoboyCard] = useState(false)
    const[motoboyMoney, setMotoboyMoney] = useState(false)

    const[change, setChange] = useState('')

    const date = new Date()

   

    const [payButton, setPayButton] = useState(false)
    const[payButtonText, setPayButtonText] = useState('')

    const[cardPayment, setCardPayment] = useState(false)

    const[paymentError, setPaymentError] = useState(false)

    const[invalidCep, setInvalidCep] = useState(false)

    const[discount, setDiscount] = useState(0)
    const[coupon, setCoupon] = useState(false)
    const[pix, setPix] = useState(false)

    const[payBtnClass, setPayBtnClass] = useState("payment-button")
    const[bairros, setBairros] = useState([])
    const[orders, setOrders] = useState()
    const [index, setIndex] = useState(0)
    const[schedule, setSchedule] = useState(0)
    const[closed, setClosed] = useState(false)
    const [orderPlaced, setOrderPlaced] = useState(false)
    


    // const[confirmation, setConfirmation] = useState(false)

    const handleIsOpen = () =>{
        if(props.shop[0]?.isOpen){
            switch (date.getDay()) {
                case 1:
                    setClosed(true)
                    break;
                    
                case 0:                    
                case 2:
                case 3:
                case 4:
  
                    if(date.getHours() < 17 && date.getHours() >= 0 ){
                        setClosed(true)
                        break;
                    }            
                    else {
                        
                        setClosed(false)
                        break;
                    }

                case 5:
                case 6:       
                    if(date.getHours() < 17 && date.getHours() >= 0){
                        setClosed(true)
                        break;
                    }            
                    else {
                        console.log(date.getDate())
                        
                        setClosed(false)
                        break;
                    }
            
                default:
                    if(date.getHours() < 17 && date.getHours() >= 0){
                        setClosed(true)
                    }            
                    else {                        
                        setClosed(false)
                    }
            }

        }
    }


    useEffect(() => {

        //TODO response from payment
        axios.get(props.apiUrl + 'deliveryRoute')
            .then(res=>setBairros(res.data))
            .then(err=>console.log(err))

    },[])

    
    useEffect(() => {

        //TODO response from payment
        axios.get(props.apiUrl + 'deliveryRoute')
            .then(res=>setBairros(res.data))
            .then(err=>console.log(err))


    },[deliver])

    useEffect(() => {

        axios.get(props.apiUrl + 'getorder')
        .then(res=>{
            setOrders(res.data)
            setIndex(res.data.length)
           
        })

        console.log(index)

        handleIsOpen()

    },[deliver, takeAway])



    const checkDelivery = (bairro) =>{


        if(bairro.length > 1){
            var cost = bairros.filter(bai=>{
                if(bai.name === bairro){
                    return bai
                }
            })
    
            // console.log(cost)
    
            
            setInvalidCep(false)
            setDoDeliver(true)
            setDontDeliver(false)
            setNb(bairro)                
            // setAdress(cepRes.logradouro)
            // 
            setFrete(cost[0].cost);
    
            setCity(cost[0].city)    
                
    
        
    
            
    
            setFinal(parseFloat(props.total) + parseFloat(frete))
        }
        


    }

    const setLocalStorage = (key, value) =>{
        
        localStorage.setItem(key, JSON.stringify(value));

          
    }

    const handleNameChange = (name) =>{setName(name)}

    const handleCardNumberChange = (cardNumber) =>{

        var arr = cardNumber.split("-")
        setCardNumber(arr.join(""))

        
    }

    const handleSchedule = (hour) =>{
        if(parseFloat(hour) <= date.getHours()){
            alert("Esse horário não está disponível para agendamento")
        }
        else{
            console.log(hour)
            setSchedule(hour)
        }
    }

    const handleChangeName = (name) =>{
        setNewName(name)
    }

    const handleSecureCodeChange = (secureCode) =>{setSecureCode(secureCode)}

    const handleExpMonthChange = (expMonth) =>{
        let arr = expMonth.split('/')

        
        
        setExpMonth(arr[0])
        setExpYear(arr[1])
        }

    const handlePaymentError = () =>{
        setPaymentError(true)
        alert('Houve um problema com seu pedido, entre em contato conosco para saber mais.')
        setLoading(false)
        setTimeout(() => {
            props.foo()
        }, 5000);
    }

    const handlePaymentSubmission = (e) =>{
        

        //todo parseint expmonth and year
        if(deliver && newName === ""){
            alert('Precisamos do seu nome para dar entrada no pedido')
        }





        else if(deliver && adress === ""){
            alert('Precisamos do seu endereço para entregar seu pedido')
        }

        else if(phone === "" || phone === "55" || phone === " " || !phone){
            alert('Precisamos do seu telefone para dar entrada no pedido')
        }

        else{
        setLoading(true);
        // setPayButton(false);
        setPaymentProcess(true)
        
        e.preventDefault();
        let t = Math.round((parseFloat(props.total) + parseInt(frete)) * 100);

        var Payment = {
            method: method,
            name: name,
            cardNumber: cardNumber,
            secureCode: secureCode,
            expMonth: expMonth,
            expYear: expYear,
            total: t,
            code: date.getTime(),
            option: option
        }

        placeOrder()


        }       

    }

 
    const handlePhone = (phoneNumber) =>{       

        let str = phoneNumber.replace(/[^\d.-]/g, '')
        
        
        setPhone(parseInt(str))

    }

    const handleHouseNumber = (houseNumber) =>{
        setHouseNumber(houseNumber)
        
    }
    
    const handleAdressChange = (thisAdress) =>{
        setAdress(thisAdress)
    }
    const handleReference = (reference) =>{
        setReference(reference)
    }

    const handleDeliver = () =>{
        deliver ? setDeliver(false) : setDeliver(true)
        setTakeAway(false)
        setDontDeliver(false)

    }

    const orderSuccess = (order) =>{

        setLoading(false)
        


        setLocalStorage('order', order)
        props.setCart([])
        props.setExtrasCart([])
        props.setTotal(0)

        props.foo()

        props.handleSuccess()



    }

    const orderError = ()=>{
        alert('Ops... algo deu errado, tente novamente!');
        

    }

    const getOrderNumber = async () =>{

        await axios.get(props.apiUrl + 'getorder')
        .then(res=>{
            setOrders(res.data)
            setIndex(res.data.length)
           
        })


    }



    const placeOrder = async () =>{

        
      

        if(newName === ""){
            alert('Precisamos do seu nome para dar entrada no pedido')
        }

        else if(phone === "" || phone === "55" || phone === " " || !phone){
            alert('Precisamos do seu telefone para dar entrada no pedido')
        }

        else if(deliver && nb === ""){
            alert('Escolha o seu bairro')
        }

        else if(newName === ""){
            alert('Precisamos do seu nome para dar entrada no pedido')
        }

        else if(deliver && adress === ""){
            alert('Precisamos do seu endereço para entregar seu pedido')
        }

        else if(closed && schedule === 0){
            console.log(schedule)
            alert("Estamos fechados, você precisa agendar o seu pedido!")
        }
        else if(!props.shop[0].isOpen && schedule === 0){
            console.log('closed')
            alert("Estamos fechados, você precisa agendar o seu pedido!")
        }

        else if(orderPlaced){
            console.log("Pedido já realizado")
        }


        else{
            setLoading(true)
            setPaymentProcess(true)
            handleTakeAway()
            handleDeliver()
            // setOrderPlaced(true)

            var order = {
                "name": newName,
                "phone": phone,
                "adress": adress +' '+ houseNumber + ' ' + reference+' ' + city,
                "cep": cep,
                "neighborhood": nb,
                "paid": false,
                "frete": frete,
                "cart": props.cart,
                "total": (parseFloat(props.total) - parseFloat(discount)) + parseInt(frete),
                "deliver": deliver,
                "readyToDelivery": false,
                "onRoute": false,
                "time": date.toLocaleString('pt-BR'), 
                "option": option === 'Motoboy-Dinheiro' ? option + ' Troco ' + change : option,
                "accepted": false,
                "orderNumber": index,
                "schedule": schedule
            }

            //TODO if response OK POST order to backend
            axios.post(props.apiUrl + 'placeOrder', order)
                .then(res=> {
                    orderSuccess(order)
                    setOrderPlaced(true)
                })
                .catch(err=> console.log(err))

        }

    }

    const handleTakeAway = () =>{
        takeAway ? setTakeAway(false) : setTakeAway(true)
        setDeliver(false)
        setFrete(0)
        // setOption('Take Away')
        setDontDeliver(false)
        handlePayButton('Take Away')
    }

    const addCardNumber = () =>{
        if(cardNumber.length < 16){
            alert("Número de cartão inválido!")
        }
        else{handlePayByApp() }

    }

    const handlePayByApp = () =>{
        payByApp ? setPayByApp(false) : setPayByApp(true)
        setMotoboy(false)
        setMotoboyCard(false)
        setMotoboyMoney(false)
        setPayButton(false)
    }

    const handleMotoboy = () =>{
        motoboy ? setMotoboy(false) : setMotoboy(true)
        setPix(false)
        setCardNumber(0)

    }

    const handlePix = () =>{
        pix ? setPix(false) : setPix(true)
        setMotoboy(false)
    }

    const handlePayButton = (payoption) =>{
        setFinal(parseFloat(props.total) + parseInt(frete))
        

        if(payoption === 'Credit'){
            setPayButtonText('Pagar e enviar')
            setPayButton(true)
            setOption(payoption)
            setMethod('credit')
            
            setCardPayment(true)
        }

        else if(payoption === 'Debit'){
            setPayButtonText('Pagar e enviar')
            setPayButton(true)
            setOption(payoption)
            setMethod('debit')
            setCardPayment(true)
        }

        else if(payoption === 'Take Away'){
            setPayButtonText('Enviar pedido')
            
            setOption('Retirada')
            
        }

        else if(payoption === 'Motoboy-Dinheiro'){
            setPayButtonText('Enviar pedido')
            setOption(payoption)

            setPayButton(true)
            setMotoboyMoney(true)
            setMotoboyCard(false)

        }

        else if(payoption === 'Motoboy-Cartão'){
            setPayButtonText('Enviar pedido')
            setOption(payoption)

            setPayButton(true)
            setMotoboyCard(true)
            setMotoboyMoney(false)

        }

        else if(payoption === 'Pix'){
            setOption(payoption)
            handlePix()

            // setPayButton(true)
            setMotoboyCard(false)
            setMotoboyMoney(false)

        }


    }

    const handleCoupon = (coupon) =>{
        switch (coupon.toUpperCase()) {
            case 'MULHER21':
                setDiscount((props.total/100)*10)
                setCoupon(true)

                
                break;
        
            default:
                break;
        }


    }


    //TODO handle submit with handlerrors for invalid form
    //TODO warnings over input fields
    //TODO animation on submit


    
    return (
        <>
        <div className="payment">
            <BiChevronLeftCircle onClick={props.foo}  className="adddecbutton modalclose" style={{color: '#fc4041', position: 'fixed', top: '40px', left: '15px'}}/>
            <div className="flex-column">
                
                <p>{'seu total até aqui R$' + props.total.toFixed(2)}</p>
                {/* <form>
                    <label>Você possui cupom de desconto?</label>
                    <input onChange={(e)=>{handleCoupon(e.target.value)}}></input>
                    <p style={{display: coupon ? 'block' : 'none'}}>Cupom Encontrado! </p>
                    <p style={{display: coupon ? 'block' : 'none', color: '#fc4041', fontWeight: '800'}}>Total: R$ {props.total - discount} </p>
                </form> */}
            </div>

            <p style={{alignSelf: "flex-start", fontSize: "0.8em"}}>Como você quer receber seu pedido?</p>
            <div className='flex-row'>
                <span onClick={handleTakeAway} style={{backgroundColor: takeAway ? '#6b6e7e' : '#f9f9f9', color: takeAway ? 'white' : '#6b6e7e'}} className="delivery-button">Retirar pessoalmente</span>
                <span onClick={handleDeliver} style={{backgroundColor: deliver ? '#6b6e7e' : '#f9f9f9', color: deliver ? 'white' : '#6b6e7e'}} className="delivery-button">Receber em casa</span>
            </div>

            <div style={{display: takeAway ? 'flex' : 'none', width: '90vw', alignItems: 'center'}} className="flex-column">

            <p style={{
                alignSelf: "flex-start", 
                fontSize: "0.8em",
                marginTop: "20px",
                marginBottom: "0",
                marginLeft: '10px'
                
                }}>seus dados</p>

            <div  className="flex-column" style={{marginLeft: '30px'}}>

                
                <form>
                    <label>Nome</label>
                    <input style={{marginBottom: '0px'}} value={newName} onChange={(e)=>{handleChangeName(e.target.value)}} placeholder="Nome Completo" type="text"></input>
                    <label>Telefone</label>
                    <InputMask onChange={(e)=>{handlePhone(e.target.value)}} value={phone} mask="+55 (99) 9 9999 9999" />

                </form>
                
                <p>nossa localização</p>

                <iframe title="como chegar" src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3554.2271319191564!2d-50.927843724551714!3d-27.02298789782044!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94e13bd18f5a69af%3A0x41713cf80e1110d7!2sRapid%C3%A3o%20Telepizzas%20Fraiburgo!5e0!3m2!1spt-BR!2sbr!4v1681737233380!5m2!1spt-BR!2sbr"} 
                    width="205" height="200" frameborder="0" 
                    allowfullscreen="true" aria-hidden="false" tabindex="0"></iframe>
                </div>

                <div style={{display: takeAway ? 'flex' : 'none'}} className="flex-column">
                    <div style={{marginTop: "20px"}}>
                        <p>Agende seu pedido</p>
                        <select style={{width: "200px", marginBottom: "20px", marginLeft: "30px"}} onChange={(e)=>handleSchedule(e.target.value)}>
                            <option value={""}>Escolha o horário </option>
                            {hours.map(hour=>(
                                <option value={hour.time}>{hour.text} </option>
                            ))}
                        </select>
                    </div>
                    <span className="payment-button" 
                    onClick={cardPayment? handlePaymentSubmission : placeOrder} >
                       {payButtonText}
                       </span>
                       {/* <div className="flex-column" style={{display: loading ? 'flex' : 'none', width: '220px', alignItems: 'center'}}>
                            <img src={clock} alt="loading" className="clock-loading" />
                            <h4>Só um Segundo...</h4>
                            <p>enquanto validamos o seu pedido</p>
                        </div>
                        <div className="flex-column" style={{display: paymentError ? 'flex' : 'none', width: '220px', alignItems: 'center', marginLeft: '20px'}}>
                            <img src={denied} style={{width: '50px', marginTop: '20px'}} alt="loading"  />
                            <h4>Houve um problema...</h4>
                            <p>seu pagamento não pode ser processado, escolha outra forma de pagamento ou pague ao receber seu pedido</p>


                        </div> */}
   
                       <p style={{marginLeft: '20px', marginTop: '10px'}} onClick={props.foo}>voltar ao menu?</p>

                   </div>

            </div>

            <div style={{display: deliver ? 'flex' : 'none', width: '90vw', alignItems: 'center'}} className="flex-column">

                <p style={{
                    alignSelf: "flex-start", 
                    fontSize: "0.8em",
                    marginTop: "20px",
                    marginBottom: "0",
                    marginLeft: '10px'
                    
                    }}>seus dados</p>

                <div  className="flex-column" style={{marginLeft: '40px', width: '220px'}}>

                    <form>
                        <label>Nome</label>
                        <input value={newName} style={{marginBottom: '0px'}} onChange={(e)=>{handleChangeName(e.target.value)}} placeholder="Nome Completo" type="text"></input>
                        <label>Telefone</label>
                        <InputMask value={phone} onChange={(e)=>{handlePhone(e.target.value)}} mask="+55 (99) 9 9999 9999" />
                        <label>Selecione o Bairro</label>
                        {/* <input value={cep} onChange={(e)=>{handleCep(e.target.value)}} /> */}
                        <p style={{fontSize: '0.7em', marginTop: '-10px'}}>{invalidCep ? 'Cep Inválido ou você está fora da nossa área de entrega' : ''}</p>
                        <select value={nb} onChange={(e)=> checkDelivery(e.target.value)}>
                            <option value="">-</option>
                            {bairros.map(bairro=>(
                                <option value={bairro.name} key={bairro.name}>{bairro.name}</option>
                            ))}
                        </select>
                        <div className="flex-column" style={{display: doDeliver ? 'flex' : 'none'}}>
                            <p>{city}</p>
                            {/* <p style={{width: '200px', textAlign: 'left'}}>{adress}</p> */}
                            
                            <p>{'Bairro '+ nb}</p>
                            <label>Logradouro</label>
                            <input placeholder="Ex. Rua, avenida ..." value={adress} onChange={(e)=> handleAdressChange(e.target.value)} type="text"/>
                            <label>número</label>
                            <p style={{fontSize: '0.8em'}}>* importante</p>
                            <input onChange={(e)=>{handleHouseNumber(e.target.value)}} placeholder="Ex. 123-b" />
                            <label>referência</label>
                            <input onChange={(e)=>{handleReference(e.target.value)}} placeholder="Ex. apto 201" />
                            <p>{'Entrega R$ ' + (parseFloat(frete)).toFixed(2)}</p>
                            <p>{'Total geral R$ ' + (parseFloat(frete) + (parseFloat(props.total)-parseFloat(discount))).toFixed(2)}</p>

                        </div>


                    </form>
                    </div>
                <p style={{
                alignSelf: "flex-start", 
                fontSize: "0.8em",
                marginTop: "20px",
                marginBottom: "0",
                marginLeft: '10px'
                
                }}>você prefere pagar...</p>
                <div className='flex-row' style={{marginLeft: '20px'}}>
                    {/* <span onClick={handlePayByApp} style={{backgroundColor: cardNumber ? '#6b6e7e' : '#f9f9f9', color: cardNumber ? 'white' : '#6b6e7e'}} className="delivery-button">pelo app</span> */}
                    <span style={{backgroundColor: motoboy ? '#6b6e7e' : '#f9f9f9', color: motoboy ? 'white' : '#6b6e7e'}} onClick={handleMotoboy} className="delivery-button">na entrega</span>
                    <span style={{backgroundColor: pix ? '#6b6e7e' : '#f9f9f9', color: pix ? 'white' : '#6b6e7e'}} onClick={()=>{handlePayButton('Pix')}} className="delivery-button" >pagar com pix</span>
                </div>


                <div className="flex-column" style={{display: motoboy? 'flex' : 'none',marginLeft: '25px'}}>
                <p style={{
                alignSelf: "flex-start", 
                fontSize: "0.8em",
                marginTop: "20px",
                marginBottom: "0",
                marginLeft: '-20px'}}>
                    o método de pagamento será?
                </p>

                    <div className='flex-row' style={{ marginLeft: '20px'}}>
                        <span style={{backgroundColor: motoboyCard ? '#6b6e7e' : '#f9f9f9', color: motoboyCard ? 'white' : '#6b6e7e'}} onClick={()=>{handlePayButton('Motoboy-Cartão')}}  className="delivery-button">cartão</span>
                        <span style={{backgroundColor: motoboyMoney ? '#6b6e7e' : '#f9f9f9', color: motoboyMoney ? 'white' : '#6b6e7e'}} onClick={()=>{handlePayButton('Motoboy-Dinheiro')}} className="delivery-button">dinheiro</span>
                    </div>

                </div>

                <div className="flex-column" style={{display: pix? 'flex' : 'none',marginLeft: '25px'}}>
                <p style={{
                alignSelf: "flex-start", 
                fontSize: "0.8em",
                marginTop: "20px",
                marginBottom: "0",
                marginLeft: '-60px'}}>
                    pix do {props.shop[0]?.name}
                </p>
                <p onClick={() => {navigator.clipboard.writeText("19119402000138")}} style={{color:'#CA3B00',marginLeft: '45px', fontWeight: '800', fontSize: '1.2em'}}>
                 <FaRegCopy style={{color: '#404355'}} onClick={() => {navigator.clipboard.writeText("19119402000138")}}/> 19.119.402/0001-38
                    </p>

                    <div>
                        <p>Agende seu pedido</p>
                        <select style={{width: "200px", marginBottom: "20px", marginLeft: "30px"}} onChange={(e)=>handleSchedule(e.target.value)}>
                            <option value={""}>Escolha o horário </option>
                            {hours.map(hour=>(
                                <option value={hour.time}>{hour.text} </option>
                            ))}
                        </select>
                    </div>

                    <span   className="payment-button" 
                        onClick={placeOrder} >
                        Enviar Pedido
                    </span>

                </div>


                <form style={{display: motoboyMoney ? 'flex' : 'none', width: '220px'}}> 
                <p style={{
                alignSelf: "flex-start", 
                fontSize: "0.8em",
                marginTop: "20px",
                marginBottom: "0",
                marginLeft: '10px'}}>
                    troco?
                </p>               
                    <input onChange={(e)=>{setChange(e.target.value)}} placeholder="Ex. Troco para 100" />
                </form>

            </div>

            <div style={{display: payButton ? 'flex' : 'none'}} className="flex-column">
                <div style={{marginTop: "20px"}}>
                    <p>Agende seu pedido</p>
                    <select style={{width: "200px", marginBottom: "20px", marginLeft: "30px" }} onChange={(e)=>handleSchedule(e.target.value)}>
                        <option value={""}>Escolha o horário </option>
                        {hours.map(hour=>(
                            <option value={hour.time}>{hour.text} </option>
                        ))}
                    </select>
                </div>
                
                <span className="payment-button" 
                onClick={cardPayment? handlePaymentSubmission : placeOrder} style={{display: payButton ? 'flex' : 'none'}} >
                    {payButtonText}
                    </span>
                    {/* <div className=''>
                        <div className="flex-column" style={{display:'flex', width: '220px', alignItems: 'center'}}>
                            <img src={clock} alt="loading" className="clock-loading" />
                            <h4>Só um Segundo...</h4>
                            <p>enquanto validamos o seu pedido</p>
                        </div>
                    </div>

                    <div className="flex-column" style={{display: paymentError ? 'flex' : 'none', width: '220px', alignItems: 'center', marginLeft: '20px'}}>
                        <img src={denied} style={{width: '50px', marginTop: '20px'}} alt="loading"  />
                        <h4>Houve um problema...</h4>
                        <p>seu pagamento não pode ser processado, escolha outra forma de pagamento ou pague ao receber seu pedido</p>


                    </div> */}

                    <p onClick={props.foo}>voltar ao menu?</p>

                </div>


        </div>

        
        <div className="loading-modal" style={{display: paymentProcess ? 'flex' : 'none'}}>
        <div className="flex-column" style={{display: loading ? 'flex' : 'none', width: '220px', alignItems: 'center'}}>
            <img src={clock} alt="loading" className="clock-loading" />
            <h4>Só um Segundo...</h4>
            <p>enquanto validamos o seu pedido</p>
        </div>
        <div className="flex-column" style={{display: paymentError ? 'flex' : 'none', width: '220px', alignItems: 'center', marginLeft: '20px'}}>
            <img src={denied} style={{width: '50px', marginTop: '20px'}} alt="loading"  />
            <h4>Houve um problema...</h4>
            <p>seu pagamento não pode ser processado, escolha outra forma de pagamento ou pague ao receber seu pedido</p>


        </div>
        </div>
        </>

        // <div className="payment">
        
        // </div>
    )
}
