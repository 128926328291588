import React from 'react'
import {BiChevronLeftCircle} from 'react-icons/bi'
import {HiCheck} from 'react-icons/hi'
import {IoIosRemove, IoIosCloseCircle} from 'react-icons/io'
import {GrFormAdd} from 'react-icons/gr'
import { FiCheckSquare, FiSquare } from 'react-icons/fi'

export default function Bordas({title, data, addBorda, addAddOn, decreaseOneExtra, addToExtraCart, extrasCart}) {


  return (
    
    <div style={{paddingLeft: "30px"}}>
        <form>
        <p>{title}</p>
            {data?.map(extra =>(
                    <div>
                        
                    <div className="extra-span" onClick={()=>{addBorda(extra)}}>
                    <div>
                        <FiSquare style={{fontSize: '1.5em', display: extra.onCart ? 'none' : 'block'}} />
                        <FiCheckSquare style={{color: 'green', fontSize: '1.5em', display: extra.onCart ? 'block' : 'none'}} /></div>
                            {extra.name} {'R$ ' + extra.price.toFixed(2)}
                        </div>
                </div>
                ))} 

            </form>
    </div>
  )
}
